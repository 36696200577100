<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-input
          placeholder="电池macid"
          clearable
          v-model="query.batteryMacId"
        ></el-input>
      </el-col>

      <el-col :span="4" v-operation>
        <el-select
          v-model="query.operationId"
          clearable
          placeholder="代理商名称"
        >
          <el-option
            v-for="(item, index) in agentList.list"
            :label="item.operationName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="电量"
          clearable
          v-model="query.soc"
        ></el-input>
      </el-col>

      <!-- <el-col :span="4">
        <el-select
          v-model="query.isOnline"
          clearable
          placeholder="是否离线"
        >
        <el-option
            label="在线"
            value="1"
          ></el-option>

          <el-option
            label="离线"
            value="0"
          ></el-option>

        </el-select>
      </el-col> -->

      <el-col :span="4">
        <el-input
          placeholder="剩余容量"
          clearable
          v-model="query.syla"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="query.inOrOut"
          clearable
          placeholder="电池状态"
        >
          <el-option
            v-for="(item, index) in this.const.BATTERY_STATUS"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="备注"
          clearable
          v-model="query.remark"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>

        <el-button type="primary" @click="showAddRemark({})">添加备注</el-button>
      </el-col>
    </el-row>
    <div>
      <el-dropdown>
        <el-button type="primary">
          导入/导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-auth="this.per.BATTERY_IMPORT">
              <UploadExcel :ref="MODAL_KEY.UPLOAD_EXCEL" @submit="uploadData">
                导入数据
              </UploadExcel>
            </el-dropdown-item>

            <el-dropdown-item v-auth="this.per.BATTERY_IMPORT">
              <span @click="doExport">
                导出数据
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="macid" prop="macid"></el-table-column>
      <el-table-column label="电量" prop="soc">
        <template #default="scope">
          <span>{{ scope.row.soc }}%</span>
        </template>
      </el-table-column>

      <el-table-column label="实际电量" prop="realSoc">
        <template #default="scope">
          <span>{{ scope.row.soc }}%</span>
        </template>
      </el-table-column>

      <el-table-column label="剩余容量" prop="syla"> </el-table-column>

      <el-table-column label="厂商编码" prop="bmscode"></el-table-column>

      <el-table-column label="循环次数" prop="bxhc"> </el-table-column>
      
      <el-table-column label="投放日期" prop="createAt"> </el-table-column>
      
      <el-table-column label="放电电流" prop="dpowerA"> </el-table-column>

      <el-table-column label="充电电流" prop="cpowerA"> </el-table-column>

      <el-table-column label="充电开关" prop="chon">
        <template #default="scope">
          <eb-badge
            :target="scope.row.chon"
            :list="this.const.ABLED"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="放电开关" prop="dhon">
        <template #default="scope">
          <eb-badge
            :target="scope.row.dhon"
            :list="this.const.ABLED"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="电池状态" prop="inOrOut">
        <template #default="scope">
          <eb-badge
            :list="this.const.BATTERY_STATUS"
            :target="scope.row.inOrOut"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="工作状态" prop="workStatus">
        <template #default="scope">
          <eb-badge
            :list="this.const.BATTERY_WORK_STATUS"
            :target="scope.row.workStatus"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="备注" prop="remark"> </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showMap(scope.row)"
            v-auth="this.per.SHOW_BATTERY_DETAIL"
          >
            定位
          </span>

          <span
            class="option option-primary"
            @click="goDetail(scope.row)"
            v-auth="this.per.SHOW_BATTERY_DETAIL"
          >
            电池详情
          </span>

          <span
            class="option option-primary"
            @click="showAddRemark(scope.row)"
            v-auth="this.per.SHOW_BATTERY_DETAIL"
          >
            修改备注
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="doUpdateRemark"
    ></EditModal>

    <MapModal
      :coord="coord"
      :ref="MODAL_KEY.MAP_MODAL"
      :modalData="modalData"
    ></MapModal>

    <HistoryRecordModal
      :coord="coord"
      :ref="MODAL_KEY.HISTORY_MAP_MODAL"
      :modalData="modalData"
    ></HistoryRecordModal>
  </div>
</template>

<script>
import {
  fetchBatteryList,
  lockBattery,
  addBatBattery,
  exportTemplate,
  fetchGPSInfo,
  exportDevice,
  addBatteryRemark,
} from "@/api/battery";
import EditModal from "./component/EditModal";
import { areas } from "@/common/area.js";
import MapModal from "./component/MapModal";
import HistoryRecordModal from "./component/HistoryRecordModal";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: { EditModal, MapModal, HistoryRecordModal },
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),
  data() {
    return {
      
      dataSource: [],
      coord: {
        latitude: "",
        longitude: "",
      },
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        operationId: -1,
      },
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
        MAP_MODAL: "MAP_MODAL",
        UPLOAD_EXCEL: "UPLOAD_EXCEL",
        HISTORY_MAP_MODAL: "HISTORY_MAP_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    goDetail(e) {
      this.$router.push("/device/battery/detail/" + e.macid);
    },

    doUpdateRemark(data){
      addBatteryRemark(data).then(res=>{
        this.$message.success("修改成功");
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
        this.getList();
      })
    },

    doExport() {
      
      exportDevice({
        ...this.query
      }).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `所有电池.xlsx`
        );
      });
    },

    showAddRemark(row) {
      this.modalData = {
        batteryMacIdList: row.macid,
        remark: row.remark
      }
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    showHistoryMap(e) {
      // 查询电池的GPS
      fetchGPSInfo([e.macid]).then((res) => {
        this.coord = {
          longitude: res.data.data.records[0].longitude,
          latitude: res.data.data.records[0].latitude,
        };
        this.modalData = e;

        this.$refs[this.MODAL_KEY.HISTORY_MAP_MODAL].showModal();
      });
    },

    showMap(e) {
      // 查询电池的GPS
      fetchGPSInfo([e.macid]).then((res) => {
        this.coord = {
          longitude: res.data.data.records[0].longitude,
          latitude: res.data.data.records[0].latitude,
        };
        this.modalData = e;

        this.$refs[this.MODAL_KEY.MAP_MODAL].showModal();
      });
    },

    lock(e) {
      this.$message.success("锁定成功");
      lockBattery().then((res) => {
        this.getList();
      });
    },
    showAddAgent() {
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchBatteryList({ ...this.query, ...this.pages }).then((res) => {
        this.dataSource = res.data.data.records;
        this.total = res.data.data.total;
        this.dataSource.map((item) => {
          item.operationName = this.agentList.map[item.operationId];
        });
      });
    },

    // 下载模板
    downTemplate() {
      exportTemplate().then((res) => {
        this.util.exportFile(res.data, "设备导入模板");
      });
    },

    // 上传文件
    uploadData(data) {
      data.append("operationId", this.$store.getters.userInfo.operationId);
      console.log(data,"data");
      addBatBattery(data).then((res) => {
        this.$message.success("导入成功!");
        this.getList();
      });
    },

    resetPage() {
      this.dataSource = [];
      this.coord = {
        latitude: "",
        longitude: "",
      };
      this.query = {
        operationId: -1,
      };
      this.pages = {
        pageIndex: 1,
        pageSize: 10,
      };
    },
  },

  beforeRouteEnter(to, from, next) {
    if (from.fullPath.indexOf("detail") > -1) {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },

  activated() {
    if (!this.$route.meta.isBack || this.dataSource.length === 0) {
      this.resetPage();
      this.$store.dispatch("fetchAgentListAll").then((res) => {
        this.getList(1);
      });
    }
  },
};
</script>
