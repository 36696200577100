<template>
  <el-dialog
    :title="modalData.macid"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="800"
    @close="closeModal"
  >
    <div id="container"></div>
    <template #footer>
      <el-button @click="closeModal"> 关闭 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
export default {
  mixins: [modalMixin],
  props: ["modalData", "coord"],
  data() {
    return {
      map: null,
    };
  },
  methods: {
    showModal() {
      this.isShow = true;
      this.$nextTick((res) => {
        this.initMap();
      });
    },

    // 初始化地图
    initMap() {
      var center = new TMap.LatLng(this.coord.latitude, this.coord.longitude); //设置中心点坐标
      //初始化地图
      this.map = new TMap.Map("container", {
        center: center,
        zoom: 16,
      });

      //创建并初始化MultiMarker
      var markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        //点标记数据数组
        geometries: [
          {
            //第二个点标记
            id: "2",
            styleId: "marker",
            position: new TMap.LatLng(
              this.coord.latitude,
              this.coord.longitude
            ),
            properties: {
              title: "marker2",
            },
          },
        ],
      });
    },

    closeModal() {
      if (this.map) {
        this.map.destroy();
      }
      this.isShow = false;
    },
  },
};
</script>
