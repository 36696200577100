<template>
  <el-dialog
    title="电池详细信息"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="电池状态：" prop="inOrOut">
        <el-select v-model="modalData.inOrOut" clearable placeholder="电池状态">
          <el-option
            v-for="(item, index) in this.const.BATTERY_STATUS"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="备注：" prop="remark">
        <el-input
          v-model="modalData.remark"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>

      <el-form-item label="电池编号：" prop="batteryMacIdList">
        <el-input
          type="textarea"
          v-model="modalData.batteryMacIdList"
          placeholder="电池编号，使用英文逗号分隔"
          rows="6"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { areas } from "@/common/area.js";
export default {
  mixins: [modalMixin],
  props: ["modalData", "modalType"],
  data() {
    return {
      
      formName: "form",
      ruleValidate: {
        inOrOut: [
          {
            required: true,
            message: "请选择电池状态",
          },
        ],
      },
    };
  },
  methods: {
    parseMacId() {
      return;
    },
    submit() {
      this.validateForm().then((res) => {
        let obj = {
          remark: this.modalData.remark,
          batteryMacIdList: this.modalData.batteryMacIdList.split(","),
          inOrOut: this.modalData.inOrOut
        };

        this.$emit("submit", obj);
      });
    },
  },
};
</script>
